import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { createMask } from '@ngneat/input-mask';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import { BehaviorSubject, Subscriber, combineLatest, from } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

import { FiltersPresetsModel, SavedFiltersPresetModel } from '@c/shared/filter-presets-menu/filters-presets.model';
import { getFlagsFilterSummary } from '@c/shared/scanner-filters/flags-filter/flags-filter.utils';
import { Features, Keys, UserSettings } from '@const';
import { SymbolFlags } from '@core/types';
import { ObservableService } from '@core1/directives/observable.service';
import { defaultPredefinedWheelPresets } from '@m1/wheel/wheel-scanner-filter/wheel-scanner-filter.data';
import { IDisplayedFilterValues, TextInputKeys } from '@m1/wheel/wheel-scanner-filter/wheel-scanner-filter.model';
import { Flags } from '@mod/symbol-smiley/symbol-smiley.model';
import { ObservableService as ObservableServiceV2 } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';
import {
  DividendsCount,
  EarningBeforeExpOptions,
  IWheelFilter,
  MinStrikeToLowestClose,
  SectorsFilter,
  allMinStrikeValues,
} from '@t/wheel/wheel.types';
import {
  convertArrayToRecord,
  getFilterControlRangeDisplayValue,
  removeComma,
  removeFormattedNumberShortCut,
} from '@u/utils';

@Component({
  selector: 'app-wheel-scanner-filter',
  templateUrl: './wheel-scanner-filter.component.html',
  styleUrls: ['./wheel-scanner-filter.component.scss'],
  providers: [DecimalPipe],
})
export class WheelScannerFilterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() listOfExpiration: string[];

  protected readonly currentTheme$ = this.observableServiceV2.theme;

  protected readonly features = Features;

  // to convert old flags in user-settings to new flags
  private readonly flagsTransitionMap: Record<SymbolFlags, Flags> = {
    [SymbolFlags.Not]: Flags.No,
    [SymbolFlags.MayBe]: Flags.Maybe,
    [SymbolFlags.YesToTrade]: Flags.Yes,
    [SymbolFlags.Never]: Flags.Never,
    [SymbolFlags.None]: Flags.None,
  };

  private readonly wheelFilterDefault: IWheelFilter = Object.freeze({
    flags: [Flags.No, Flags.Maybe, Flags.Yes, Flags.Never, Flags.None],
    strikeFrom: '',
    strikeTo: '',
    expiration: 'All',
    roiFrom: '',
    roiTo: '',
    premiumFrom: '',
    premiumTo: '',
    minStrike: MinStrikeToLowestClose.AllStrikes,
    marketCapFrom: {
      filterValue: '',
      visibleValue: '',
    },
    marketCapTo: {
      filterValue: '',
      visibleValue: '',
    },
    peRatioFrom: '',
    peRatioTo: '',
    dividends: DividendsCount.All,
    changeInPercentFrom: '',
    changeInPercentTo: '',
    dropInPercentFrom: '',
    dropInPercentTo: '',
    sectors: SectorsFilter.All,
    earningBeforeExp: EarningBeforeExpOptions.NotBeforeExp,
  });

  protected displayedFilterValues: IDisplayedFilterValues = {
    flags: '',
    strike: '',
    roi: '',
    premium: '',
    marketCap: '',
    PERatio: '',
    changeInPercent: '',
    dropInPercent: '',
  };
  protected readonly minStrikeReadableValues = {
    [MinStrikeToLowestClose.Below]: 'Below',
    [MinStrikeToLowestClose.AtLowestCloseInd]: 'At Lowest Ind',
    [MinStrikeToLowestClose.AllStrikes]: 'All Strikes',
    [MinStrikeToLowestClose.AtOrBellowLowestInd]: 'At or Below Lowest Ind',
  };

  protected readonly earningBeforeExpReadableValues = {
    [EarningBeforeExpOptions.Any]: 'Any',
    [EarningBeforeExpOptions.NotBeforeExp]: 'Not Before Expiration',
    [EarningBeforeExpOptions.OnlyBeforeExp]: 'Only Before Expiration',
  };

  protected sectorsFilterVisibleValues = {
    [SectorsFilter.All]: 'All',
    [SectorsFilter.ExcludePortfolioSectors]: 'Exclude my portfolio sectors',
  };

  protected isMarketCapFromValid = true;
  protected isMarketCapToValid = true;
  protected wheelFilter: IWheelFilter = {
    ...this.wheelFilterDefault,
    marketCapFrom: { ...this.wheelFilterDefault.marketCapFrom },
    marketCapTo: { ...this.wheelFilterDefault.marketCapTo },
  };
  protected hideFilter = false;

  protected strikeFrom = new UntypedFormControl(this.wheelFilter.strikeFrom);
  protected strikeTo = new UntypedFormControl(this.wheelFilter.strikeTo);

  protected expiration = new UntypedFormControl(this.wheelFilter.expiration);
  protected minStrike = new UntypedFormControl(this.wheelFilter.minStrike);
  protected expirationDates: string[] = [];

  protected roiFrom = new UntypedFormControl(this.wheelFilter.roiFrom);
  protected roiTo = new UntypedFormControl(this.wheelFilter.roiTo);

  protected premiumFrom = new UntypedFormControl(this.wheelFilter.premiumFrom);
  protected premiumTo = new UntypedFormControl(this.wheelFilter.premiumTo);

  protected marketCapFrom = new UntypedFormControl(this.wheelFilter.marketCapFrom.filterValue);
  protected marketCapTo = new UntypedFormControl(this.wheelFilter.marketCapTo.filterValue);

  protected peRatioFrom = new UntypedFormControl(this.wheelFilter.peRatioFrom);
  protected peRatioTo = new UntypedFormControl(this.wheelFilter.peRatioTo);

  protected changeInPercentFrom = new UntypedFormControl(this.wheelFilter.changeInPercentFrom);
  protected changeInPercentTo = new UntypedFormControl(this.wheelFilter.changeInPercentTo);

  protected dropInPercentFrom = new UntypedFormControl(this.wheelFilter.dropInPercentFrom);
  protected dropInPercentTo = new UntypedFormControl(this.wheelFilter.dropInPercentTo);

  protected dividends = new UntypedFormControl(this.wheelFilter.dividends);
  protected sectors = new UntypedFormControl(this.wheelFilter.sectors);
  protected earningsBeforeExp = new FormControl<EarningBeforeExpOptions>(this.wheelFilter.earningBeforeExp);

  protected delayTimer: NodeJS.Timeout;

  protected filterCount = true;
  protected tooltipPosition: TooltipPosition = 'below';

  protected strikePriceInputMask = createMask({
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    digitsOptional: false,
    showMaskOnHover: false,
    showMaskOnFocus: false,
    rightAlign: true,
    allowMinus: false,
    undoOnEscape: false,
  });

  protected marketCapInputMask = createMask({
    casing: 'upper',
    regex: '0^$|([0-9|,]{1,19}[(kK|mM|bB|tT)]?)',
    placeholder: '',
    showMaskOnHover: false,
    showMaskOnFocus: false,
    rightAlign: true,
    undoOnEscape: false,
  });

  protected changeInInputMask = createMask({
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    min: -999.99,
    max: 999.99,
    digitsOptional: true,
    showMaskOnHover: false,
    showMaskOnFocus: false,
    rightAlign: true,
    allowMinus: true,
    undoOnEscape: false,
  });

  protected minStrikeFilterValues = MinStrikeToLowestClose;
  protected sectorsValues = SectorsFilter;
  protected readonly earningBeforeExpValues = EarningBeforeExpOptions;

  protected dividendsCount = DividendsCount;
  protected textInputKeys = TextInputKeys;

  private saveDataStream = new BehaviorSubject(null);
  private subscriber = new Subscriber();

  // for filters-presets-menu component
  protected selectedFiltersPresetId: string | null = null;
  protected wheelFiltersPredefinedPresets: Array<FiltersPresetsModel<IWheelFilter>> = [];
  protected wheelFiltersCustomPresets: Array<FiltersPresetsModel<IWheelFilter>> = [];

  protected minStrikeFilterHint = `
    Below - show symbols where Min Strike below the Lowest Close Ind.
    At Lowest Ind - show symbols where Min Strike is equal or $1 above the Lowest Close Ind.
    At or Below Lowest Ind - show combined symbols for 'Below' and 'At Lowest Ind'
    All Strikes - show all strike prices.
  `;
  protected dividendsFilterHint = `
    All - show all symbols.
    Yes - show symbols which have paid dividends in the past 180 days.
    No - show symbols which do not have paid dividends in the past 180 days.
  `;
  protected earningsBeforeExtFilterHint = `
    This filter applies only to symbols and their strikes based on upcoming earnings dates.\n
    - Any: No filter applied.
    - Not Before Expiration: Shows symbols and strikes where 1) Earnings date is after the expiration date, OR 2) earnings are today and announced before market open.
    - Only Before Expiration: Shows only symbols and strikes where earnings date is before expiration date(s).
  `;
  protected marketCapFilterHint = `
    Nano - below $50M
    Micro - between $50M and $300M
    Small - between $300M and $2B
    Medium - between $2B and $10B
    Large - between $10B and $200B
    Very Large - above $200B\n
    (1T = 1,000B = 1,000,000M = 1,000,000,000K = 1,000,000,000,0000)
    (1B = 1,000M = 1,000,000K = 1,000,000,000)
    (1M = 1,000K = 1,000,000)
    (1K = 1,000)
  `;

  protected PERatioFilterHint = `
    Very Low - between 0.01 and 10
    Low - between 10 and 20
    Medium - between 20 and 30
    High - between 30 and 40
    Very High - above 40
  `;

  protected changeInFilterHint = `
    It defines the change from yesterday's closing price to the current price.
    As an example, if you would like to see only stocks that dropped at least 1% from yesterday's closing price,you would enter ‘-1’ in the LESS THAN field.
  `;

  constructor(
    private observableService: ObservableService,
    private observableServiceV2: ObservableServiceV2,
    protected userDataService: UserDataService,
    private decimalPipe: DecimalPipe,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.observableService.wheelFilters.next(this.wheelFilter);

    combineLatest([
      from(this.userDataService.getAsJSON(UserSettings.WheelFiltersPresets)),
      from(this.userDataService.get(UserSettings.SelectedWheelFiltersPresetId)),
    ])
      .pipe(take(1))
      .subscribe(([presets, selectedPresetId]) => {
        this.restorePresets(presets, selectedPresetId);
      });

    this.subscriber.add(
      this.observableServiceV2.isWheelFiltersHidden.pipe(take(1)).subscribe((isWheelFiltersHidden) => {
        this.hideFilter = Boolean(isWheelFiltersHidden);
      }),
    );

    this.subscriber.add(
      // use only first value to apply saved settings from app-loader
      this.observableServiceV2.wheelFiltersState.pipe(take(1)).subscribe((savedState) => {
        if (savedState) {
          this.applySavedSettings(savedState);
        }
      }),
    );

    this.expirationDates = this.listOfExpiration;

    this.filterCount = this.compareFiltersState(this.wheelFilter, this.wheelFilterDefault);

    this.tooltipBottomRightPosition();

    this.savePresets();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.expirationDates = this.listOfExpiration;

    if (
      changes.listOfExpiration &&
      !changes.listOfExpiration.currentValue.includes(this.wheelFilter.expiration) &&
      this.wheelFilter.expiration !== 'All'
    ) {
      // set default value if current expiration value is not in available options
      this.wheelFilter = { ...this.wheelFilter, expiration: 'All' };
      this.expiration.setValue('All');
    }

    this.onFilterChange();
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  // PRESETS METHODS - START

  protected async onSelectPreset(data: { id: string } | null): Promise<void> {
    if (!data) {
      const prevSelectedPresetId = this.selectedFiltersPresetId;
      this.selectedFiltersPresetId = null;

      if (prevSelectedPresetId !== this.selectedFiltersPresetId) {
        await this.userDataService.set(UserSettings.SelectedWheelFiltersPresetId, null);
      }

      return;
    }

    const selectedPreset = [...this.wheelFiltersPredefinedPresets, ...this.wheelFiltersCustomPresets].find(
      (item) => item.id === data.id,
    );

    if (!selectedPreset) {
      return;
    }

    const prevSelectedFiltersPresetId = this.selectedFiltersPresetId;
    this.selectedFiltersPresetId = data.id;

    // postpone changes in filters (and possibly large lists as a result) to close presets-menu without lags
    setTimeout(async () => {
      let settingsToApply = {
        ...JSON.parse(JSON.stringify(this.wheelFilterDefault)),
        ...JSON.parse(JSON.stringify(selectedPreset.settings)),
      };

      // use recommendedExpDate for predefined presets
      if (this.wheelFiltersPredefinedPresets.some((item) => item.id === selectedPreset.id)) {
        settingsToApply = { ...settingsToApply, expiration: this.getRecommendedExpDate() };
      }

      // check saved expiration from custom preset before use
      if (this.wheelFiltersCustomPresets.some((item) => item.id === selectedPreset.id)) {
        const expiration = this.expirationDates.includes(settingsToApply.expiration)
          ? settingsToApply.expiration
          : 'All';
        settingsToApply = { ...settingsToApply, expiration };
      }

      this.applySavedSettings(settingsToApply);
      await this.userDataService.set(UserSettings.WheelFiltersState, this.wheelFilter);
    });

    if (prevSelectedFiltersPresetId !== this.selectedFiltersPresetId) {
      await this.userDataService.set(UserSettings.SelectedWheelFiltersPresetId, this.selectedFiltersPresetId);
    }
  }

  protected onSavePresetSettings(data: { id: string } | null): void {
    if (!data) {
      return;
    }

    this.wheelFiltersCustomPresets = this.wheelFiltersCustomPresets.map((item) => {
      if (item.id === data.id) {
        return { ...item, settings: { ...this.wheelFilter } };
      }

      return item;
    });

    this.saveDataStream.next(null);
  }

  protected onPredefinedPresetsUpdated(updatedPresets: Array<FiltersPresetsModel<IWheelFilter>>): void {
    this.wheelFiltersPredefinedPresets = [...updatedPresets];
    this.saveDataStream.next(null);
  }

  protected onCustomPresetsUpdated(updatedPresets: Array<FiltersPresetsModel<IWheelFilter>>): void {
    this.wheelFiltersCustomPresets = [...updatedPresets];
    this.saveDataStream.next(null);
  }

  protected async savePresets(): Promise<void> {
    this.subscriber.add(
      this.saveDataStream.pipe(debounceTime(300)).subscribe(async () => {
        if (this.wheelFiltersPredefinedPresets.length || this.wheelFiltersCustomPresets.length) {
          const dataToSave: SavedFiltersPresetModel<IWheelFilter> = {
            predefined: this.wheelFiltersPredefinedPresets.map(({ id }) => ({ id })),
            custom: this.wheelFiltersCustomPresets,
          };

          await this.userDataService.set(UserSettings.WheelFiltersPresets, dataToSave);
        }
      }),
    );
  }

  protected restorePresets(
    savedData: SavedFiltersPresetModel<IWheelFilter> | null,
    selectedPresetId: string | null,
  ): void {
    let predefinedPresetsOrder = {};

    if (savedData && savedData.predefined) {
      predefinedPresetsOrder = convertArrayToRecord(
        savedData.predefined.map((item, index) => ({ ...item, index })),
        'id',
      );
    }

    const restoredPredefinedPresets = [...defaultPredefinedWheelPresets].sort((a, b) => {
      return (
        (predefinedPresetsOrder[a.id]?.index ?? defaultPredefinedWheelPresets.length + 1) -
        (predefinedPresetsOrder[b.id]?.index ?? defaultPredefinedWheelPresets.length + 1)
      );
    });

    this.wheelFiltersPredefinedPresets = [...restoredPredefinedPresets];
    this.wheelFiltersCustomPresets = [...(savedData?.custom ?? [])];

    if (selectedPresetId !== null) {
      const selectedPreset = [...this.wheelFiltersPredefinedPresets, ...this.wheelFiltersCustomPresets].find(
        (item) => item.id === selectedPresetId,
      );

      // set selectedPreset when saved presets list is applied for the component (do not remove setTimeout)
      setTimeout(() => {
        this.selectedFiltersPresetId = selectedPreset?.id ?? null;
      }, 200);
    }
  }

  // PRESETS METHODS - END

  public setFilterValuesForControls(): void {
    this.expiration.setValue(this.wheelFilter.expiration);
    this.strikeFrom.setValue(this.wheelFilter.strikeFrom);
    this.strikeTo.setValue(this.wheelFilter.strikeTo);
    this.roiFrom.setValue(this.wheelFilter.roiFrom);
    this.roiTo.setValue(this.wheelFilter.roiTo);
    this.premiumFrom.setValue(this.wheelFilter.premiumFrom);
    this.premiumTo.setValue(this.wheelFilter.premiumTo);
    this.minStrike.setValue(this.wheelFilter.minStrike);
    this.peRatioFrom.setValue(this.wheelFilter.peRatioFrom);
    this.peRatioTo.setValue(this.wheelFilter.peRatioTo);
    this.dividends.setValue(this.wheelFilter.dividends);
    this.marketCapFrom.setValue(this.wheelFilter.marketCapFrom.visibleValue);
    this.marketCapTo.setValue(this.wheelFilter.marketCapTo.visibleValue);
    this.changeInPercentFrom.setValue(this.wheelFilter.changeInPercentFrom);
    this.changeInPercentTo.setValue(this.wheelFilter.changeInPercentTo);
    this.dropInPercentFrom.setValue(this.wheelFilter.dropInPercentFrom);
    this.dropInPercentTo.setValue(this.wheelFilter.dropInPercentTo);
    this.sectors.setValue(this.wheelFilter.sectors);
    this.earningsBeforeExp.setValue(this.wheelFilter.earningBeforeExp);
  }

  public async toggleFilter(): Promise<void> {
    this.hideFilter = !this.hideFilter;

    this.observableServiceV2.isWheelFiltersHidden.next(this.hideFilter ? 1 : 0);
    await this.userDataService.set(UserSettings.IsWheelFiltersHidden, this.hideFilter ? 1 : 0);
  }

  public flagSelect(): void {
    // disable scroll in conservative/aggressive tabs
    this.observableService.isAbletoUpDown.next(false);
  }

  protected onChangeFlags(value: Flags[]): void {
    const prevFilterState = { ...this.wheelFilter };
    this.wheelFilter = { ...this.wheelFilter, flags: value };

    if (!this.compareFiltersState(this.wheelFilter, prevFilterState)) {
      this.onSelectPreset(null);
    }
    this.onFilterChange();
  }

  public focusOutEvent(event, key?: TextInputKeys): void {
    // input-mask doesn't update form-control value when required digits after dot are not filled
    // force update on focus-out for text-inputs to have relevant value in form-control
    if (this[key]) {
      this[key].setValue(event.target.value);
    }

    event.preventDefault();
    // enable scroll in conservative/aggressive tabs
    this.observableService.isAbletoUpDown.next(true);
  }

  public focusEvent(event): void {
    event.preventDefault();
    event.stopPropagation();
    // disable scroll in conservative/aggressive tabs
    this.observableService.isAbletoUpDown.next(false);
  }

  private onTextInputChange(event: KeyboardEvent, inputKey: TextInputKeys): void {
    const input = document.getElementById(inputKey) as HTMLInputElement;
    const inputVal = +input.value;

    if ([Keys.BACKSPACE, Keys.DELETE].includes(event.key as Keys) && !inputVal) {
      this[inputKey].setValue('');
    }
  }

  public onFilterChange(event?: KeyboardEvent, inputKey?: TextInputKeys): void {
    if (inputKey) {
      this.onTextInputChange(event, inputKey);
    }

    const prevFiltersState = { ...JSON.parse(JSON.stringify(this.wheelFilter)) };

    this.validateMarketCapValues();
    this.wheelFilter.expiration = this.expiration.value;
    this.wheelFilter.minStrike = this.minStrike.value;
    this.wheelFilter.dividends = this.dividends.value;
    this.wheelFilter.sectors = this.sectors.value;
    this.wheelFilter.earningBeforeExp = this.earningsBeforeExp.value;
    this.wheelFilter.marketCapFrom.visibleValue = this.marketCapFrom.value;
    this.wheelFilter.marketCapTo.visibleValue = this.marketCapTo.value;

    Object.values(this.textInputKeys).forEach((key) => {
      if (key === TextInputKeys.MarketCapFrom || key === TextInputKeys.MarketCapTo) {
        this.wheelFilter[key].filterValue = removeFormattedNumberShortCut(this[key].value);
      } else {
        // input-mask doesn't update form-control value when required digits after dot are not filled
        // take value from html-input during editing process to have relevant value in this.wheelFilter
        const input = document.getElementById(key) as HTMLInputElement;
        this.wheelFilter[key] = removeComma(input?.value ?? this[key].value);
      }
    });

    this.wheelFilter.marketCapFrom.filterValue = this.isMarketCapFromValid
      ? this.wheelFilter.marketCapFrom.filterValue
      : '';
    this.wheelFilter.marketCapTo.filterValue = this.isMarketCapToValid ? this.wheelFilter.marketCapTo.filterValue : '';
    this.filterCount = this.compareFiltersState(this.wheelFilter, this.wheelFilterDefault);
    this.displayedFilterValues = this.getFilterValuesToDisplay(this.wheelFilter);

    if (!this.compareFiltersState(this.wheelFilter, prevFiltersState)) {
      this.onSelectPreset(null);
    }

    clearTimeout(this.delayTimer);

    this.delayTimer = setTimeout(async () => {
      this.observableService.wheelFilters.next(this.wheelFilter);
      this.observableServiceV2.wheelFiltersState.next(this.wheelFilter);

      await this.userDataService.set(UserSettings.WheelFiltersState, this.wheelFilter);
    }, 1000);

    this.changeDetectorRef.markForCheck();
  }

  public getFilterValuesToDisplay(filters: IWheelFilter): IDisplayedFilterValues {
    const defaultNumberFormat = '1.0-2';

    const strikeFrom = this.decimalPipe.transform(filters.strikeFrom, defaultNumberFormat);
    const strikeTo = this.decimalPipe.transform(filters.strikeTo, defaultNumberFormat);
    const roiFrom = this.decimalPipe.transform(filters.roiFrom, defaultNumberFormat);
    const roiTo = this.decimalPipe.transform(filters.roiTo, defaultNumberFormat);
    const premiumFrom = this.decimalPipe.transform(filters.premiumFrom, defaultNumberFormat);
    const premiumTo = this.decimalPipe.transform(filters.premiumTo, defaultNumberFormat);
    const peRatioFrom = this.decimalPipe.transform(filters.peRatioFrom, defaultNumberFormat);
    const peRatioTo = this.decimalPipe.transform(filters.peRatioTo, defaultNumberFormat);

    const changeInPercentFrom = this.decimalPipe.transform(filters.changeInPercentFrom, defaultNumberFormat);
    const changeInPercentTo = this.decimalPipe.transform(filters.changeInPercentTo, defaultNumberFormat);
    const dropInPercentFrom = this.decimalPipe.transform(filters.dropInPercentFrom, defaultNumberFormat);
    const dropInPercentTo = this.decimalPipe.transform(filters.dropInPercentTo, defaultNumberFormat);

    return {
      flags: getFlagsFilterSummary(filters.flags),
      strike: getFilterControlRangeDisplayValue(strikeFrom, strikeTo),
      roi: getFilterControlRangeDisplayValue(roiFrom, roiTo),
      premium: getFilterControlRangeDisplayValue(premiumFrom, premiumTo),
      marketCap: getFilterControlRangeDisplayValue(
        filters.marketCapFrom.visibleValue,
        filters.marketCapTo.visibleValue,
      ),
      PERatio: getFilterControlRangeDisplayValue(peRatioFrom, peRatioTo),
      changeInPercent: getFilterControlRangeDisplayValue(changeInPercentFrom, changeInPercentTo),
      dropInPercent: getFilterControlRangeDisplayValue(dropInPercentFrom, dropInPercentTo),
    };
  }

  public formatMarketCapValue(isFromValue: boolean): void {
    let marketCapValue = isFromValue ? this.marketCapFrom.value : this.marketCapTo.value;
    if (!marketCapValue) {
      return;
    }

    let marketCapShortCut = '';
    const shortcutsList = 'KTBM';
    const lastLetterInMarketCapFilterValue = marketCapValue.charAt(marketCapValue.length - 1);

    if (shortcutsList.includes(lastLetterInMarketCapFilterValue)) {
      marketCapShortCut = marketCapValue[marketCapValue.length - 1];
    }

    marketCapValue = this.decimalPipe.transform(Number(marketCapValue.replace(/\D/g, '')), '1.0-3') + marketCapShortCut;

    if (isFromValue) {
      this.marketCapFrom.setValue(marketCapValue);
    } else {
      this.marketCapTo.setValue(marketCapValue);
    }
  }

  public validateMarketCapValues(): void {
    this.isMarketCapFromValid = Number(removeFormattedNumberShortCut(this.marketCapFrom.value)) <= 100000000000000;
    this.isMarketCapToValid = Number(removeFormattedNumberShortCut(this.marketCapTo.value)) <= 100000000000000;
  }

  public resetFilters(): void {
    this.expiration.setValue('All');
    this.strikeFrom.setValue('');
    this.strikeTo.setValue('');
    this.roiFrom.setValue('');
    this.roiTo.setValue('');
    this.premiumFrom.setValue('');
    this.premiumTo.setValue('');
    this.minStrike.setValue(MinStrikeToLowestClose.AllStrikes);
    this.filterCount = false;
    this.marketCapFrom.setValue('');
    this.marketCapTo.setValue('');
    this.peRatioFrom.setValue('');
    this.peRatioTo.setValue('');
    this.changeInPercentFrom.setValue('');
    this.changeInPercentTo.setValue('');
    this.dropInPercentFrom.setValue('');
    this.dropInPercentTo.setValue('');
    this.dividends.setValue(DividendsCount.All);
    this.sectors.setValue(SectorsFilter.All);
    this.earningsBeforeExp.setValue(this.wheelFilterDefault.earningBeforeExp);

    const prevFilterState = { ...this.wheelFilter };
    this.wheelFilter = {
      ...this.wheelFilterDefault,
      flags: [...this.wheelFilterDefault.flags],
    };

    if (!this.compareFiltersState(this.wheelFilter, prevFilterState)) {
      this.onSelectPreset(null);
    }

    // reset local storage & observable
    this.onFilterChange();
  }

  public resetToRecommendedFilters(): void {
    const recommendedExp = this.getRecommendedExpDate();

    this.expiration.setValue(recommendedExp);
    this.strikeFrom.setValue('15');
    this.strikeTo.setValue('');
    this.roiFrom.setValue('30');
    this.roiTo.setValue('');
    this.premiumFrom.setValue('0.1');
    this.premiumTo.setValue('');
    this.minStrike.setValue(MinStrikeToLowestClose.AllStrikes);
    this.filterCount = false;
    this.marketCapFrom.setValue('5B');
    this.marketCapTo.setValue('');
    this.peRatioFrom.setValue('');
    this.peRatioTo.setValue('50');
    this.changeInPercentFrom.setValue('');
    this.changeInPercentTo.setValue('-1');
    this.dropInPercentFrom.setValue('');
    this.dropInPercentTo.setValue('');
    this.dividends.setValue(DividendsCount.AtLeastOne);
    this.sectors.setValue(SectorsFilter.All);
    this.earningsBeforeExp.setValue(EarningBeforeExpOptions.NotBeforeExp);

    // reset local storage & observable
    this.onFilterChange();
  }

  public selectInputValue(event): void {
    event.target.focus();
    event.target.select();
  }

  public stopPropagation($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

  public tooltipBottomRightPosition(): string {
    return (this.tooltipPosition = window.innerWidth < 378 ? 'right' : 'left');
  }

  private getRecommendedExpDate(): string {
    const date = moment().tz('America/New_York');
    const dayOfWeek = date.clone().isoWeekday();

    let recommendedExp = 'All';

    if (dayOfWeek < 3) {
      recommendedExp =
        this.expirationDates.find((exp) =>
          moment(exp).isBetween(
            date.clone().startOf('isoWeek').format('YYYY-MM-DD'),
            date.clone().endOf('isoWeek').format('YYYY-MM-DD'),
          ),
        ) || 'All';
    }

    if (dayOfWeek >= 3) {
      const nextWeekDate = date.clone().add(1, 'week');

      recommendedExp =
        this.expirationDates.find((exp) =>
          moment(exp).isBetween(
            nextWeekDate.clone().startOf('isoWeek').format('YYYY-MM-DD'),
            nextWeekDate.clone().endOf('isoWeek').format('YYYY-MM-DD'),
          ),
        ) || 'All';
    }

    return recommendedExp;
  }

  private applySavedSettings(savedState: Partial<IWheelFilter>): void {
    Object.keys(this.wheelFilter).forEach((key) => {
      if (key === 'flags' && Array.isArray(savedState[key])) {
        // saved settings can include old flags
        const prevState: Array<SymbolFlags | Flags> = savedState[key];
        // if it's an old flag return new version of it, otherwise keep it
        this.wheelFilter[key] = prevState.map((flag) => this.flagsTransitionMap[flag] ?? flag);

        return;
      }

      if (savedState[key] !== undefined) {
        this.wheelFilter[key] = savedState[key];
      }
    });

    // set default expiration value if current expiration value is not in available options
    // only if listOfExpiration is already received
    if (
      this.listOfExpiration.length > 0 &&
      !this.listOfExpiration.includes(this.wheelFilter.expiration) &&
      this.wheelFilter.expiration !== 'All'
    ) {
      this.wheelFilter = { ...this.wheelFilter, expiration: 'All' };
    }

    this.wheelFilter.minStrike =
      allMinStrikeValues.find((val) => val === this.wheelFilter.minStrike) || MinStrikeToLowestClose.AllStrikes;
    const isDividendValueValid = Object.values(DividendsCount).find(
      (dividend) => dividend === this.wheelFilter.dividends,
    );

    if (!isDividendValueValid) {
      this.wheelFilter.dividends = DividendsCount.All;
    }

    this.setFilterValuesForControls();
    this.filterCount = this.compareFiltersState(this.wheelFilter, this.wheelFilterDefault);

    this.displayedFilterValues = this.getFilterValuesToDisplay(this.wheelFilter);
    this.observableService.wheelFilters.next(this.wheelFilter);
  }

  private compareFiltersState(
    filtersState: IWheelFilter = this.wheelFilter,
    defaultFiltersState: IWheelFilter = this.wheelFilterDefault,
  ): boolean {
    return _.isEqual(
      {
        ...filtersState,
        marketCapFrom: { ...filtersState.marketCapFrom },
        marketCapTo: { ...filtersState.marketCapTo },
      },
      {
        ...defaultFiltersState,
        marketCapFrom: { ...defaultFiltersState.marketCapFrom },
        marketCapTo: { ...defaultFiltersState.marketCapTo },
      },
    );
  }
}
