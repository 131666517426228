import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { Subject, Subscriber, from, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, withLatestFrom } from 'rxjs/operators';

import { GroupedWatchlistComponent } from '@c/grouped-watchlist/grouped-watchlist.component';
import { IPortfolioTrade } from '@c/portfolio/portfolio.model';
import {
  DEFAULT_UPDATE_FLAGS_DELAY_MS,
  ExchangeCountriesCodes,
  Features,
  UserSettings,
  WatchlistType,
  saveTabStateDebounceTime,
  selectWheelScannerSymbolDebounceTime,
} from '@const';
import { ITableData, TopLevelTabs } from '@m1/wheel/wheel-scanner-tab/wheel-scanner.model';
import { WheelService } from '@m1/wheel/wheel.service';
import { Flags, ISymbolSmiley, SmileyListType } from '@mod/symbol-smiley/symbol-smiley.model';
import { IWatchlistItem } from '@mod/watchlist/watchlist.model';
import { DialogsService } from '@s/common';
import { SmileyDataService } from '@s/smiley-data.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { UserDataService } from '@s/user-data.service';
import { WatchlistService } from '@s/watchlist.service';
import {
  DataWindowUpdateSource,
  IScannerResponseV2,
  IWheelFilter,
  IWheelStockOption,
  IWheelStockOptionRaw,
  StockOptionAttributeType,
} from '@t/wheel/wheel.types';
import { convertArrayToRecord } from '@u/utils';
import { ObservableService } from 'src/app/core/directives/observable.service';
import { ObservableService as ObservableServiceV2 } from 'src/app/services/observable.service';
import { WheelScannerService } from './wheel-scanner.service';

@Component({
  selector: 'app-wheel-scanner',
  templateUrl: './wheel-scanner.component.html',
  styleUrls: ['./wheel-scanner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WheelScannerComponent implements OnInit, OnDestroy {
  public scannerDataAll: Array<ITableData> = [];
  public scannerDataAggressive: Array<ITableData> = [];
  public scannerDataConservative: Array<ITableData> = [];

  public scannerDataRaw: IWheelStockOptionRaw[] = [];
  public scannerResults: Array<IWheelStockOption> = [];
  protected allSymbolsRecord: Record<string, ISymbol> = {};

  protected currentSymbol$ = this.observableServiceV2.wheelSymbol;
  protected readonly currentSymbolForLists$ = this.currentSymbol$.pipe(map((id) => ({ security_id: id })));

  protected readonly features = Features;
  protected readonly smileyListTypes = SmileyListType;
  protected readonly watchlistTypes = WatchlistType;
  protected readonly userSettings = UserSettings;

  public tabs: Array<TopLevelTabs> = [
    // TopLevelTabs.ScannerAll, // to uncomment with tab in template
    TopLevelTabs.ScannerConservative,
    TopLevelTabs.ScannerAggressive,
    TopLevelTabs.Watchlist,
    TopLevelTabs.Portfolio,
  ];
  public selectedTab: TopLevelTabs = TopLevelTabs.ScannerConservative;
  public selectSymbolTimeout: NodeJS.Timeout;

  public uniqueListOfExpiration: string[] = [];
  public showTimer = false; // passed into wheel-scanner-dump

  protected currentWatchlist: IWatchlistItem[] | null = null;
  protected watchlistTableItems: Array<ITableData> | null = null;

  private wheelSmileys: ISymbolSmiley[] = [];
  private existedFilters: IWheelFilter;
  private saveWatchlistScannerTabState$ = new Subject<number>();

  private subscriber = new Subscriber();

  @Output() private changeCurrentSymbol: EventEmitter<IWheelStockOption[]> = new EventEmitter(null);

  @ViewChild('groupedWatchlist') groupedWatchlist: GroupedWatchlistComponent;

  constructor(
    private scannerService: WheelScannerService,
    private wheelService: WheelService,
    private observableService: ObservableService,
    private userDataService: UserDataService,
    private observableServiceV2: ObservableServiceV2,
    private smileyDataService: SmileyDataService,
    private symbolsService: SymbolsService,
    private dialogsService: DialogsService,
    private watchlistService: WatchlistService,
  ) {
    this.watchlistService.get(WatchlistType.Wheel, true).subscribe((res) => {
      this.currentWatchlist = res;
      this.updateContentForWatchlist(res);
    });

    this.subscriber.add(
      this.watchlistService.updateWatchlistSignal$
        .pipe(switchMap(() => this.watchlistService.get(WatchlistType.Wheel, true)))
        .subscribe((res) => {
          this.currentWatchlist = res;
          this.updateContentForWatchlist(res);
        }),
    );

    // for updates from my-settings og data-window
    this.subscriber.add(
      this.smileyDataService.updateSymbolsSmileySignal$
        .pipe(withLatestFrom(this.smileyDataService.lastRelevantFlags$))
        .subscribe(([updatedListType, lastRelevantFlags]) => {
          if (updatedListType !== SmileyListType.Wheel) {
            return;
          }

          this.wheelSmileys = this.smileyDataService.updateCurrentFlagsWithLastRelevantFlags(
            this.wheelSmileys,
            lastRelevantFlags[SmileyListType.Wheel],
          );

          this.transformScannerData();
          this.updateContentForWatchlist(this.currentWatchlist);
        }),
    );

    this.subscriber.add(
      this.observableService.wheelFilters.subscribe((res) => {
        this.existedFilters = res;
        this.transformScannerData();
      }),
    );

    this.subscriber.add(
      this.scannerService.lastReceivedFilteredPortfolioSectorsList$
        .pipe(
          distinctUntilChanged(_.isEqual),
          // take(2) // take only initial value (0) and first result (existing list) - uncomment to return
        )
        .subscribe(() => {
          // trigger update scanner data in case existing portfolio list is received after new scanner results
          this.transformScannerData();
        }),
    );

    // get initial smiley-data
    this.smileyDataService.get(SmileyListType.Wheel).subscribe((res) => {
      this.scannerService.smileyTrigger$.next(res); // legacy, for old wheel-watchlist

      this.wheelSmileys = res;

      this.transformScannerData();
      this.updateContentForWatchlist(this.currentWatchlist);
    });
  }

  ngOnInit(): void {
    this.observableServiceV2.isWheelScannerLoadingFirstTime$.next(true);

    this.subscriber.add(
      this.observableServiceV2.wheelTopTab.subscribe((tabIndex) => {
        if (tabIndex === null || tabIndex === undefined) {
          return;
        }

        const tab = this.getTabByIndex(tabIndex);

        if (this.selectedTab !== tab) {
          this.selectedTab = tab;
        }
      }),
    );

    this.subscriber.add(
      this.wheelService.updateStockList$.pipe(debounceTime(500)).subscribe(() => {
        this.getScannerData();
      }),
    );

    this.subscriber.add(
      this.wheelService.isTimerActive$.pipe(distinctUntilChanged()).subscribe((isTimerActive) => {
        this.showTimer = isTimerActive;
      }),
    );

    this.subscriber.add(
      this.saveWatchlistScannerTabState$.pipe(debounceTime(saveTabStateDebounceTime)).subscribe(async (tabIndex) => {
        this.selectedTab = this.getTabByIndex(tabIndex);
        await this.userDataService.set(UserSettings.WheelTopTab, tabIndex);
      }),
    );

    from(this.symbolsService.getAll())
      .pipe(take(1))
      .subscribe((allSymbols) => {
        // use only US symbols here
        const filteredSymbols = allSymbols.filter((item) => item.country_code === ExchangeCountriesCodes.US);
        this.allSymbolsRecord = convertArrayToRecord(filteredSymbols, 'security_id');
      });
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  public onSetCurrentSymbol(event: { symbol: string; security_id: number }, atr?: StockOptionAttributeType): void {
    const sources = {
      [TopLevelTabs.ScannerAll]: DataWindowUpdateSource.ScannerAll,
      [TopLevelTabs.ScannerAggressive]: DataWindowUpdateSource.ScannerAggressive,
      [TopLevelTabs.ScannerConservative]: DataWindowUpdateSource.ScannerConservative,
      [TopLevelTabs.Watchlist]: DataWindowUpdateSource.Watchlist,
      [TopLevelTabs.Portfolio]: DataWindowUpdateSource.ScannerAll,
    };

    clearTimeout(this.selectSymbolTimeout);
    this.selectSymbolTimeout = setTimeout(async () => {
      if (!this.allSymbolsRecord[event.security_id]) {
        await this.dialogsService.showNoDataForSymbolMessage(event.symbol);
        return;
      }

      const selectedOptions = [TopLevelTabs.ScannerAggressive, TopLevelTabs.ScannerConservative].includes(
        this.selectedTab,
      )
        ? this.scannerResults.filter((r) => r.symbol === event.symbol && r.atr_type === atr)
        : this.scannerResults.filter((r) => r.symbol === event.symbol);

      this.changeCurrentSymbol.next(selectedOptions);
      this.wheelService.updateDataWindowSymbol$.next({
        source: sources[this.selectedTab],
        symbol: {
          id: selectedOptions.length > 0 ? selectedOptions[0].security_id : event.security_id,
          name: event.symbol,
          options: selectedOptions,
        },
      });

      if (this.observableServiceV2.isWheelCalculator.getValue()) {
        await this.userDataService.set(UserSettings.IsWheelCalculator, false);
      }

      await this.userDataService.set(UserSettings.WheelSymbol, event.security_id);
    }, selectWheelScannerSymbolDebounceTime);
  }

  protected onUpdateFilteredPortfolioList(filteredTrades: IPortfolioTrade[]): void {
    const sectorsList = filteredTrades
      .reduce((acc: string[], item) => {
        if (!acc.includes(item.sector)) {
          acc.push(item.sector);
        }

        return acc;
      }, [])
      .sort(); // just default sort to keep the same order

    this.scannerService.lastReceivedFilteredPortfolioSectorsList$.next(sectorsList);
  }

  protected onChangeSmiley(event: { security_id: number; flag: Flags }): void {
    if (this.wheelSmileys.some((item) => item.security_id === event.security_id)) {
      this.wheelSmileys = [...this.wheelSmileys].map((item) => {
        if (item.security_id === event.security_id) {
          return { ...item, flag: event.flag };
        }

        return item;
      });
    } else {
      this.wheelSmileys = [...this.wheelSmileys, { security_id: event.security_id, flag: event.flag, id: null }];
    }

    setTimeout(() => {
      this.transformScannerData();
      this.updateContentForWatchlist(this.currentWatchlist);
    }, DEFAULT_UPDATE_FLAGS_DELAY_MS);
  }

  protected addSectionIntoGroupedWatchlist(): void {
    this.groupedWatchlist.addNewGroup();
  }

  private getScannerData(): void {
    this.observableServiceV2.isWheelScannerLoading.next(true);
    this.scannerService
      .getWheelScannerDataV2()
      .pipe(take(1))
      .subscribe(
        (response) => {
          if (!response) {
            this.uniqueListOfExpiration = [];
            this.scannerService.uniqueListOfExpiration$.next(this.uniqueListOfExpiration);
            this.scannerService.firstScannerResponseReceived.next(true);
            return;
          }

          if (response.securityDetails) {
            // currently, "country_code" for scanner-results can be only "US"
            Object.keys(response.securityDetails).forEach((key) => {
              // use available if it exists, if not - set "US"
              response.securityDetails[key].country_code = response.securityDetails[key].country_code ?? 'US';
            });
          }

          // to have a relevant date fot the timer before "isWheelScannerLoading" is set to "false"
          const lastScannerUpdateDate =
            response.securityDetails && Array.isArray(response.securityDetails) && response.securityDetails.length > 0
              ? response.securityDetails[0].created_date
              : null;
          this.wheelService.lastScannerUpdateDate$.next(lastScannerUpdateDate ?? null);

          this.scannerService.uniqueListOfExpiration$.next(response?.expirationDates ?? []);
          this.observableServiceV2.isWheelScannerLoading.next(false);
          this.observableServiceV2.isWheelScannerLoadingFirstTime$.next(false);
          this.wheelService.lastReceivedSecurityDetails$.next(response.securityDetails);

          // transform scannerResponseV2 into existing format
          this.scannerDataRaw = this.transformRawScannerResultsV2toV1(response);
          this.uniqueListOfExpiration = response.expirationDates ?? [];

          this.transformScannerData();
          this.scannerService.firstScannerResponseReceived.next(true);
        },
        () => {
          this.uniqueListOfExpiration = [];
          this.scannerService.uniqueListOfExpiration$.next(this.uniqueListOfExpiration);
        },
      );
  }

  private transformScannerData(): void {
    if (this.scannerDataRaw && this.scannerDataRaw.length > 0) {
      const optionsWithFlag = this.addFlagToData(this.roundMinPremium(this.scannerDataRaw), this.wheelSmileys);

      this.scannerService.lastReceivedScannerData$.next(optionsWithFlag);
      this.wheelService.lastScannerUpdateDate$.next(this.scannerDataRaw[0]?.created_date);
      this.wheelService.updateDataWindowFromScanner$.next();

      this.scannerResults = [...optionsWithFlag].filter((r) => r.symbol && r.company);

      // is_scanner, but not A/C
      this.scannerDataAll = this.normalize(
        this.scannerService.filterStocks(
          [...optionsWithFlag].filter((r) => r.symbol && r.company && r.atr_type === 'all' && r.is_scanner),
          this.existedFilters,
          this.uniqueListOfExpiration,
        ),
      );

      this.scannerDataAggressive = this.normalize(
        this.scannerService.filterStocks(
          [...optionsWithFlag].filter((r) => r.symbol && r.company && r.atr_type === 'aggressive' && r.is_scanner),
          this.existedFilters,
          this.uniqueListOfExpiration,
        ),
      );

      this.scannerDataConservative = this.normalize(
        this.scannerService.filterStocks(
          [...optionsWithFlag].filter((r) => r.symbol && r.company && r.atr_type === 'conservative' && r.is_scanner),
          this.existedFilters,
          this.uniqueListOfExpiration,
        ),
      );
    }

    if (this.scannerDataRaw && this.scannerDataRaw.length === 0) {
      this.scannerService.lastReceivedScannerData$.next([]);
      this.wheelService.lastScannerUpdateDate$.next(null);
      this.wheelService.updateDataWindowFromScanner$.next();
      this.scannerResults = [];
      this.scannerDataAll = [];
      this.scannerDataAggressive = [];
      this.scannerDataConservative = [];
    }
  }

  private normalize(options: IWheelStockOption[]): ITableData[] {
    const data = options.map((res) => {
      return {
        security_id: res.security_id,
        symbol: res.symbol,
        company: res.company,
        flag: res.flag,
      };
    });

    const uniqueData: ITableData[] = [];

    data.forEach((d) => {
      const dd = uniqueData.find((u) => u.company === d.company && u.symbol === d.symbol);

      if (!dd) {
        uniqueData.push({
          id: d.security_id,
          symbol: d.symbol,
          company: d.company,
          flag: d.flag,
          security_id: d.security_id,
        });
      }
    });

    return _.orderBy(uniqueData, ['symbol'], ['asc']);
  }

  private addFlagToData(data: IWheelStockOptionRaw[], smileys: ISymbolSmiley[]): IWheelStockOption[] {
    const smileyMap = smileys.reduce((acc, smiley) => {
      acc[smiley.security_id] = smiley;
      return acc;
    }, {});

    return data.map((stockOptionRaw) => {
      return { flag: smileyMap[stockOptionRaw.security_id]?.flag ?? Flags.None, ...stockOptionRaw };
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private roundMinPremium(data) {
    return data.map((item) => {
      return { min_premium: Number(item.min.toFixed(2)), ...item };
    });
  }

  public onTabChanged(event: { index: number }): void {
    this.saveWatchlistScannerTabState$.next(event.index);
  }

  private getTabByIndex(index: number): TopLevelTabs {
    return this.tabs[index];
  }

  public getTabIndex(tab: TopLevelTabs): number {
    return this.tabs.indexOf(tab);
  }

  private transformRawScannerResultsV2toV1(response: IScannerResponseV2): IWheelStockOptionRaw[] {
    if (!response.scannerResults) {
      return [];
    }

    return response.scannerResults.map((option, index) => ({
      id: index,
      long_descriptions: response.securityDetails[option.security_id].long_description,
      min_premium: null,
      updated_on: null,
      ...response.securityDetails[option.security_id],
      ...option,
    }));
  }

  private updateContentForWatchlist(watchlistItems: IWatchlistItem[] | null): void {
    if (!watchlistItems) {
      return;
    }

    const uniqueWatchlistItemsMap = new Map<number, IWatchlistItem>();
    const wheelSmileysMap = convertArrayToRecord(this.wheelSmileys, 'security_id');

    // remove duplicates
    watchlistItems.forEach((item) => {
      if (!uniqueWatchlistItemsMap.has(item.security_id)) {
        uniqueWatchlistItemsMap.set(item.security_id, item);
      }
    });

    this.watchlistTableItems = Array.from(uniqueWatchlistItemsMap.values()).map((item) => ({
      id: item.id,
      security_id: item.security_id,
      symbol: item.symbol,
      company: item.company_name,
      flag: wheelSmileysMap[item.security_id]?.flag ?? Flags.None,
    }));
  }
}
