import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ChartReadyEventModel, ChartType, CustomChartIndicators } from '@c/doubled-chart/doubled-chart.model';
import { IGetCustomIndicators } from '@c/shared/symbol-chart/symbol-chart.model';
import { TradingLogSymbolSummaryPanelItemValuesModel } from '@c/trading-log/shared';
import { ChartSaveData, Features } from '@const';
import { breakEvenLineColor, costBasisLineColor } from '@constants/chart';
import { ISymbolData } from '@core/types';
import { ObservableService as ObservableServiceV1 } from '@core1/directives/observable.service';
import { EditionsService } from '@s/editions.service';
import { ObservableService } from '@s/observable.service';
import { ISymbol } from '@s/symbols.service';
import { UserDataService } from '@s/user-data.service';
import { Subject, Subscription, filter } from 'rxjs';
import { LineIndicator } from 'src/app/indicators/line.indicator';
import { MinStrikePriceIndicator } from 'src/app/indicators/min-strike-price.indicator';
import {
  ChartingLibraryWidgetOptions,
  EntityId,
  IChartWidgetApi,
  IChartingLibraryWidget,
} from 'src/assets/charting_library';
import { WheelService } from '../wheel.service';

@Component({
  selector: 'app-wheel-chart',
  templateUrl: './wheel-chart.component.html',
  styleUrls: ['./wheel-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelChartComponent implements OnInit, OnDestroy {
  protected readonly features = Features;
  protected readonly chartTypes = ChartType;
  protected readonly chartSavedDataKey = ChartSaveData.Wheel;
  protected readonly isDeveloperAccount = this.observableService.isDeveloperAccount.getValue();
  protected readonly currentSymbol$ = this.observableService.wheelSymbol;
  protected readonly resetChart$ = new Subject<void>();

  protected readonly widgetOptionsOverridesFor1DayChart: Partial<ChartingLibraryWidgetOptions> = {};
  protected readonly widgetOptionsOverridesFor15MinChart: Partial<ChartingLibraryWidgetOptions> = {};
  protected readonly widgetOptionsOverridesFor5MinChart: Partial<ChartingLibraryWidgetOptions> = {};
  protected readonly widgetOptionsOverridesFor1MinChart: Partial<ChartingLibraryWidgetOptions> = {};
  protected readonly disabledIndicators: CustomChartIndicators[] = [CustomChartIndicators.RobsIndicators];

  protected tradingLogSymbolSummaryPanelWheelState = this.observableService.tradingLogSymbolSummaryPanelWheelState;
  protected breakEvenLine = new LineIndicator('', breakEvenLineColor);
  protected costBasisLine = new LineIndicator('', costBasisLineColor);

  private activeChart: IChartWidgetApi | null = null;
  private minStrikeIndicator: MinStrikePriceIndicator = new MinStrikePriceIndicator(
    [],
    this.observableServiceV1.wheelFilters.getValue(),
    null,
    this.observableService.theme.getValue(),
  );
  public tradingLogSymbolSummaryPanelItemValuesModel: TradingLogSymbolSummaryPanelItemValuesModel = {};
  private subscription = new Subscription();

  constructor(
    private observableService: ObservableService,
    private observableServiceV1: ObservableServiceV1,
    private wheelService: WheelService,
    private editionsService: EditionsService,
    private userDataService: UserDataService,
  ) {}

  ngOnInit(): void {
    this.observableService.theme.subscribe((theme) => {
      this.minStrikeIndicator.currentTheme = theme;
    });

    // TODO: use combineLatest and generate ready to use data based on filters and options
    this.observableServiceV1.wheelFilters.subscribe((filters) => {
      this.minStrikeIndicator.symbolFilters = filters;
    });

    this.subscription.add(
      this.wheelService.optionsForMinStrike$.pipe(filter((items) => !!items)).subscribe((optionsForMinStrike) => {
        this.minStrikeIndicator.symbolFullData = [...optionsForMinStrike] as unknown as ISymbolData[];
      }),
    );
  }

  public getChartShapeIdsToExclude = (): EntityId[] => {
    return [this.minStrikeIndicator.minStrikePriceLineId, this.breakEvenLine.lineId, this.costBasisLine.lineId];
  };

  public getTimescaleMarks = async (
    activeChart: IChartWidgetApi,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    symbol: ISymbol,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    timeZone: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bars: unknown[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resolution: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-function-type
    onDataCallback: Function, // to use specific fn: (args: ArgsModel) => ResultModel
  ): Promise<unknown[]> => {
    if (!activeChart) {
      return;
    }

    return [];
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public loadChartData = async (symbol: ISymbol): Promise<void> => {};

  protected async getExpectedMoveOptions(): Promise<{
    observableKeyOne: string;
    observableKeyTwo: string;
  }> {
    return Promise.resolve({
      observableKeyOne: 'showExpectedMoveOneOnWheel',
      observableKeyTwo: 'showExpectedMoveTwoOnWheel',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected async afterSavedDataRestored(chartWidget: IChartingLibraryWidget, chartType: ChartType): Promise<void> {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected getCustomIndicators: IGetCustomIndicators = (PineJS) => {
    return [];
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onSymbolChanged(symbol: ISymbol): void {}

  protected async onChartReady(event: ChartReadyEventModel): Promise<void> {
    if (!event) {
      return;
    }

    this.activeChart = event.chart;
    this.minStrikeIndicator.activeChart = this.activeChart;
    this.addSummaryPanelLines();
  }

  protected redirectToLowHighIndicatorDemoPage(): void {
    this.editionsService.redirectToDemoPage(Features.LowHighIndicator);
  }

  public addSummaryPanelLines(): void {
    this.breakEvenLine.removeLine(this.activeChart);

    if (this.tradingLogSymbolSummaryPanelItemValuesModel.breakEven) {
      this.breakEvenLine.showLine(this.activeChart, this.tradingLogSymbolSummaryPanelItemValuesModel.breakEven);
    }

    this.costBasisLine.removeLine(this.activeChart);

    if (this.tradingLogSymbolSummaryPanelItemValuesModel.costBasis) {
      this.costBasisLine.showLine(this.activeChart, this.tradingLogSymbolSummaryPanelItemValuesModel.costBasis);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
