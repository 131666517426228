<div class="wheel-legend-container">
  <app-wheel-chart-legend
    *appHideForUnavailableFeature="features.LowHighIndicator; else lowHighIndicatorPlaceholder"
  ></app-wheel-chart-legend>

  <ng-template #lowHighIndicatorPlaceholder>
    <div class="low-high-indicator-placeholder">
      <button
        (click)="$event.preventDefault(); redirectToLowHighIndicatorDemoPage()"
        mat-button
        class="mat-button-link link-button locked-for-current-edition"
      >
        Lowest / Highest Close
      </button>
    </div>
  </ng-template>
</div>
<div
  class="app-tv-chart-wheel-container"
  id="tv_chart_container_wheel"
></div>
<app-doubled-chart
  [defaultChartType]="chartTypes.Day1"
  [afterSavedDataRestored]="afterSavedDataRestored.bind(this)"
  [chartSavedDataKey]="chartSavedDataKey"
  [chartSavedDataKeyPrefix]="'wheel'"
  [isDeveloperAccount]="isDeveloperAccount"
  [getChartShapeIdsToExclude]="getChartShapeIdsToExclude"
  [getCustomIndicators]="getCustomIndicators"
  [getTimescaleMarks]="getTimescaleMarks"
  [getExpectedMoveOptions]="getExpectedMoveOptions"
  [loadChartData]="loadChartData"
  [symbol$]="currentSymbol$"
  [resetChart$]="resetChart$"
  [disabledIndicators]="disabledIndicators"
  [widgetOptionsOverridesFor1DayChart]="widgetOptionsOverridesFor1DayChart"
  [widgetOptionsOverridesFor15MinChart]="widgetOptionsOverridesFor15MinChart"
  [widgetOptionsOverridesFor5MinChart]="widgetOptionsOverridesFor5MinChart"
  [widgetOptionsOverridesFor1MinChart]="widgetOptionsOverridesFor1MinChart"
  (chartReady)="onChartReady($event)"
  (symbolChanged)="onSymbolChanged($event)"
  class="chart-wheel-strategy-container"
></app-doubled-chart>

<div
  *ngIf="false"
  class="chart-overlay"
></div>
