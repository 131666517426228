import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment-timezone';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { EasternTimeZoneName } from '@const';
import { WheelScannerResponse, WheelSmiley } from '@core1/model';
import { RelativelyToMarketTimeOptions } from '@mod/admin';
import { ISymbolSmiley } from '@mod/symbol-smiley/symbol-smiley.model';
import {
  DividendsCount,
  EarningBeforeExpOptions,
  IScannerResponse,
  IScannerResponseV2,
  IWheelFilter,
  IWheelStockOption,
  MinStrikeToLowestClose,
  SectorsFilter,
} from '@t/wheel/wheel.types';
import { convertToEasternTime } from '@u/utils';

@Injectable({ providedIn: 'root' })
export class WheelScannerService {
  public _symbolChart$: BehaviorSubject<Array<IWheelStockOption> | null> = new BehaviorSubject(null);

  public data$: BehaviorSubject<WheelScannerResponse[]> = new BehaviorSubject([]);

  // specific flag for wheel-data-window, to restore previous selected symbol (if it was selected from scanner)
  public firstScannerResponseReceived = new Subject<boolean>();

  public lastReceivedScannerData$: BehaviorSubject<Array<IWheelStockOption>> = new BehaviorSubject([]);
  public lastReceivedFilteredPortfolioSectorsList$ = new BehaviorSubject<string[]>([]);
  public uniqueListOfExpiration$ = new BehaviorSubject<string[]>([]);

  public lastUpdatedTime: BehaviorSubject<string> = new BehaviorSubject(null);

  public timer: BehaviorSubject<string> = new BehaviorSubject(null);

  public smileyTrigger$: BehaviorSubject<ISymbolSmiley[]> = new BehaviorSubject([]); // legacy, for old wheel-watchlist

  public onSelectChart$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  // listened by data-window, passes stock-options
  public getSymbolChart$(): Observable<Array<IWheelStockOption> | null> {
    return this._symbolChart$.asObservable();
  }

  public setSymbolChart$(value: Array<IWheelStockOption>): void {
    this._symbolChart$.next(value);
  }

  public getWheelScannerDataV2(): Observable<IScannerResponseV2> {
    return this.http.post<{ result: IScannerResponseV2 }>('/v2/wheel/scannerV2', {}).pipe(map((res) => res.result));
  }

  public getDataBySymbol(symbolId: number): Observable<IScannerResponse> {
    return this.http
      .post<{ result: IScannerResponse }>('/v2/wheel', { security_ids: [symbolId] })
      .pipe(map((res) => res.result));
  }

  public getWheelSmileyByUserId(): Observable<WheelSmiley[]> {
    return this.http.get<{ result: WheelSmiley[] }>('/wheel/smiley').pipe(map((res) => res.result));
  }

  public filterStocks(
    data: Array<IWheelStockOption> | null,
    filters: IWheelFilter,
    expirationDateOptions: string[] = [],
    portfolioSectors: string[] = this.lastReceivedFilteredPortfolioSectorsList$.getValue(),
  ): Array<IWheelStockOption> | null {
    let filteredData = [...data];

    if (!filteredData || !filters) {
      return filteredData;
    }

    const hasStrikeFromFilter = filters.strikeFrom !== '';
    const hasStrikeToFilter = filters.strikeTo !== '';
    const hasRoiFromFilter = filters.roiFrom !== '';
    const hasRoiToFilter = filters.roiTo !== '';
    const hasPremiumFromFilter = filters.premiumFrom !== '';
    const hasPremiumToFilter = filters.premiumTo !== '';
    const hasExpirationFilter = filters.expiration !== 'All';
    const hasFlagsFilter = filters.flags.length > 0;
    const hasPERatioFromFilter = filters.peRatioFrom !== '';
    const hasPERatioToFilter = filters.peRatioTo !== '';
    const hasMarketCapFromFilter = filters.marketCapFrom.filterValue !== '';
    const hasMarketCapToFilter = filters.marketCapTo.filterValue !== '';
    const hasDividendsAtLeastOneFilter = filters.dividends === DividendsCount.AtLeastOne;
    const hasDividendsNoneFilter = filters.dividends === DividendsCount.None;
    const hasChangeInFromFilter = filters.changeInPercentFrom !== '';
    const hasChangeInToFilter = filters.changeInPercentTo !== '';
    const hasDropInFromFilter = filters.dropInPercentFrom !== '';
    const hasDropInToFilter = filters.dropInPercentTo !== '';

    filteredData = filteredData.filter(
      (el) =>
        (!hasStrikeFromFilter || el.strike_price >= (filters.strikeFrom as number)) &&
        (!hasStrikeToFilter || el.strike_price <= (filters.strikeTo as number)) &&
        (!hasRoiFromFilter || el.annularized >= (filters.roiFrom as number)) &&
        (!hasRoiToFilter || el.annularized <= (filters.roiTo as number)) &&
        (!hasPremiumFromFilter || el.option_premium >= (filters.premiumFrom as number)) &&
        (!hasPremiumToFilter || el.option_premium <= (filters.premiumTo as number)) &&
        (!hasExpirationFilter || el.expiration === filters.expiration) &&
        (!hasFlagsFilter || filters.flags.includes(el.flag)) &&
        (!hasPERatioFromFilter || el.pe_ratio >= (filters.peRatioFrom as number)) &&
        (!hasPERatioToFilter || (el.pe_ratio <= (filters.peRatioTo as number) && el.pe_ratio !== null)) &&
        (!hasMarketCapFromFilter || el.market_cap >= (filters.marketCapFrom.filterValue as number)) &&
        (!hasMarketCapToFilter || el.market_cap <= (filters.marketCapTo.filterValue as number)) &&
        (!hasDividendsNoneFilter || el.dividends_count <= 0) &&
        (!hasDividendsAtLeastOneFilter || el.dividends_count > 0) &&
        (!hasChangeInFromFilter || el.price_change_percentage >= (filters.changeInPercentFrom as number)) &&
        (!hasChangeInToFilter || el.price_change_percentage <= (filters.changeInPercentTo as number)) &&
        (!hasDropInFromFilter ||
          (el.drop_percentage >= (filters.dropInPercentFrom as number) && el.drop_percentage !== null)) &&
        (!hasDropInToFilter ||
          (el.drop_percentage <= (filters.dropInPercentTo as number) && el.drop_percentage !== null)),
    );

    if (filters.minStrike && filters.minStrike !== MinStrikeToLowestClose.AllStrikes) {
      const minStrikeDataBySymbol: { [key: string]: number } = {};

      filteredData.forEach((element) => {
        minStrikeDataBySymbol[element.symbol] =
          !minStrikeDataBySymbol[element.symbol] || minStrikeDataBySymbol[element.symbol] > element.strike_price
            ? element.strike_price
            : minStrikeDataBySymbol[element.symbol];
      });

      filteredData = filteredData.filter((el) => {
        switch (filters.minStrike) {
          case MinStrikeToLowestClose.Below:
            return minStrikeDataBySymbol[el.symbol] < el.close_40_min;
          case MinStrikeToLowestClose.AtLowestCloseInd:
            return (
              minStrikeDataBySymbol[el.symbol] <= el.close_40_min + 1 &&
              minStrikeDataBySymbol[el.symbol] >= el.close_40_min
            );
          case MinStrikeToLowestClose.AtOrBellowLowestInd:
            return minStrikeDataBySymbol[el.symbol] <= el.close_40_min + 1;
          default:
            return true;
        }
      });
    }

    if (filters.earningBeforeExp !== EarningBeforeExpOptions.Any) {
      filteredData = this.filterStockOptionsByEarningsBeforeExpiration(filteredData, filters, expirationDateOptions);
    }

    if (filters.sectors === SectorsFilter.ExcludePortfolioSectors) {
      filteredData = filteredData.filter((item) => !portfolioSectors.includes(item.sector));
    }

    return filteredData;
  }

  public filterStockOptions(
    data: Array<IWheelStockOption> | null,
    filters: IWheelFilter,
    expirationDateOptions: string[] = [],
  ): Array<IWheelStockOption> | null {
    let filteredData = [...data];

    if (!filteredData || !filters) {
      return [];
    }

    const hasStrikeFromFilter = filters.strikeFrom !== '';
    const hasStrikeToFilter = filters.strikeTo !== '';
    const hasRoiFromFilter = filters.roiFrom !== '';
    const hasRoiToFilter = filters.roiTo !== '';
    const hasPremiumFromFilter = filters.premiumFrom !== '';
    const hasPremiumToFilter = filters.premiumTo !== '';
    const hasExpirationFilter = filters.expiration !== 'All';
    const hasDropInFromFilter = filters.dropInPercentFrom !== '';
    const hasDropInToFilter = filters.dropInPercentTo !== '';

    filteredData = [...filteredData].filter(
      (el) =>
        (!hasStrikeFromFilter || Number(el.strike_price) >= Number(filters.strikeFrom)) &&
        (!hasStrikeToFilter || Number(el.strike_price) <= Number(filters.strikeTo)) &&
        (!hasRoiFromFilter || Number(el.annularized) >= Number(filters.roiFrom)) &&
        (!hasRoiToFilter || Number(el.annularized) <= Number(filters.roiTo)) &&
        (!hasPremiumFromFilter || Number(el.option_premium) >= Number(filters.premiumFrom)) &&
        (!hasPremiumToFilter || Number(el.option_premium) <= Number(filters.premiumTo)) &&
        (!hasExpirationFilter || el.expiration === filters.expiration) &&
        (!hasDropInFromFilter ||
          (el.drop_percentage >= (filters.dropInPercentFrom as number) && el.drop_percentage !== null)) &&
        (!hasDropInToFilter ||
          (el.drop_percentage <= (filters.dropInPercentTo as number) && el.drop_percentage !== null)),
    );

    if (filters.earningBeforeExp !== EarningBeforeExpOptions.Any) {
      filteredData = this.filterStockOptionsByEarningsBeforeExpiration(filteredData, filters, expirationDateOptions);
    }

    return filteredData;
  }

  private filterStockOptionsByEarningsBeforeExpiration(
    data: Array<IWheelStockOption> | null,
    filters: IWheelFilter,
    expirationDateOptions: string[] = [],
  ): Array<IWheelStockOption> | null {
    if (!data) {
      return [];
    }

    if (!filters || filters.earningBeforeExp === EarningBeforeExpOptions.Any) {
      return [...data];
    }

    const currentDate = moment.tz(EasternTimeZoneName);

    // IMPORTANT: filter's logic is different from the text in selected option text or filter's hint
    // do not rely on "obvious" or straightforward logic, check Specs for details

    if (filters.earningBeforeExp === EarningBeforeExpOptions.NotBeforeExp) {
      return [...data].filter((item) => {
        // if no earnings for symbol - include it into the result
        if (!item.earning_report_date || !item.expiration) {
          return true;
        }

        const earningsDate = convertToEasternTime(item.earning_report_date);
        const isBeforeMarket = item.earning_before_after_market === RelativelyToMarketTimeOptions.BeforeMarket;
        const expirationDate = convertToEasternTime(item.expiration);

        if (earningsDate.isSame(currentDate, 'day') && isBeforeMarket) {
          return true;
        }

        // handle the case when [ED <= exp_1] AND [All OR exp_2] are selected in "Expiration" filter
        // should return items with [item.exp === exp_2]
        const exp1 = expirationDateOptions[0];
        const exp2 = expirationDateOptions[1];
        const exp1MomentET = exp1 ? convertToEasternTime(exp1) : null;
        const isEarningsSameOrBeforeExp1 = earningsDate.isSameOrBefore(exp1MomentET, 'day');

        if (exp1 && exp2 && isEarningsSameOrBeforeExp1) {
          return false;
        }

        // when [exp_1 < ED <= exp_2] OR [ED > exp_2] - use default behavior
        return earningsDate.isAfter(expirationDate, 'day');
      });
    }

    if (filters.earningBeforeExp === EarningBeforeExpOptions.OnlyBeforeExp) {
      return [...data].filter((item) => {
        const earningsDate = item.earning_report_date ? convertToEasternTime(item.earning_report_date) : null;
        const expirationDate = item.expiration ? convertToEasternTime(item.expiration) : null;

        // if no earnings for symbol - include it into the result
        if (!earningsDate || !earningsDate.isValid() || !expirationDate || !expirationDate.isValid()) {
          return true;
        }

        // handle the case when [ED <= exp_1] AND [All OR exp_2] are selected in "Expiration" filter
        // should return items with [item.exp === exp_2]
        const exp1 = expirationDateOptions[0];
        const exp2 = expirationDateOptions[1];

        const exp1MomentET = exp1 ? convertToEasternTime(exp1) : null;
        const exp2MomentET = exp2 ? convertToEasternTime(exp2) : null;

        const isEarningsSameBeforeExp1 = earningsDate.isSameOrBefore(exp1MomentET, 'day');
        const isEarningsAfterExp1 = earningsDate.isAfter(exp1MomentET, 'day');
        const isEarningsSameOrBeforeExp2 = earningsDate.isSameOrBefore(exp2MomentET, 'day');

        if (exp1MomentET?.isValid() && isEarningsSameBeforeExp1) {
          const isAll = filters.expiration === 'All';
          const isFirstExpiration = filters.expiration === exp1;
          const isSecondExpiration = filters.expiration === exp2;

          if (isAll) {
            return item.expiration === exp1 || item.expiration === exp2;
          } else if (isFirstExpiration) {
            return item.expiration === exp1;
          } else if (isSecondExpiration) {
            return item.expiration === exp2;
          }
        }

        if (exp1MomentET?.isValid() && exp2MomentET?.isValid() && isEarningsAfterExp1 && isEarningsSameOrBeforeExp2) {
          return item.expiration === exp2;
        }

        return false;
      });
    }

    return [...data];
  }
}
